.rc-collapse {
  &.experiences {
    border: none;
    background-color: transparent;

    .rc-collapse {
      &-content {
        color: inherit
      }

      &-expand-icon {
        transform: scale(2)
      }
    }
  }
}
