.about {
  box-shadow: 0 3em 3em -2em #867777;

  .interest-list span:first-child {
    text-transform: capitalize;
  }

  .interest-list span:not(:last-child):after {
    content: ", ";
  }
}
