.nav {
  .logo {
    filter: contrast(0) brightness(0) drop-shadow(3px 6px 0 #fee2e2);
  }

  @media screen and (min-width: 769px) {
    .anchor:not(:first-child) {
      position: relative;

      &.active:after {
        width: 60%;
      }

      &:after {
        content: "";
        position: absolute;
        background: #fee2e2;
        height: 3px;
        top: 35px;
        left: 0;
        width: 0;

        z-index: -1;
        transition: width 0.3s ease;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .active {
      font-weight: 600;
      color: #b91c1c;
    }
  }
}
