@use 'styles/About.scss';
@use 'styles/Accordionner.scss';
@use 'styles/Intro.scss';
@use 'styles/Nav.scss';
@use 'styles/Skills.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.App {
  .main-content {
    flex-basis: 80%;
  }

  .sidebar {
    flex-basis: 20%;
  }
}
