.skills {
  .line-wrapper {
    position: relative;

    .line.border-b-8 {
      border-bottom-width: 10px;
      z-index: 3;
    }

    .line:before {
      content: "";
      position: absolute;
      top: 5px;

      z-index: -1;

      height: 1px;
      width: 100%;
      border-bottom: 2px solid #ddd;
    }
  }
}
