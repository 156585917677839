.intro {
  .avatar {
    background-size: contain;
    background-image: none;
    box-shadow: 0 3em 3em -2em #867777;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background-image: url("../assets/img/with-grey-bg.jpg");
      background-size: contain;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }

    img {
      filter: hue-rotate(42deg) saturate(0.3) brightness(1.2);
      opacity: 1;
      transition: opacity 1s 0.2s ease-in-out;
    }

    &:hover {
      img {
        opacity: 0;
      }

      &:before {
        opacity: 1;
      }
    }
  }
}
